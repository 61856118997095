// Conversation
export const SET_ACTIVE_CONTEXT = 'SET_ACTIVE_CONTEXT';
export const CONTINUE_CONVERSATION = 'CONTINUE_CONVERSATION';
export const STOP_CONVERSATION = 'STOP_CONVERSATION';
export const STORE_USERNAME = 'STORE_USERNAME';

// Projects

// Contacts
export const SET_MESSAGE_SUBJECT = 'SET_MESSAGE_SUBJECT';
export const SET_MESSAGE_CONTENT = 'SET_MESSAGE_CONTENT';
export const CLEAR_MESSAGE_DATA = 'CLEAR_MESSAGE_DATA';
