import {

} from '../constants/actionTypes';

import { projectsInitialState } from './initialState';

export default function projects(state = projectsInitialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
